import _propTypes from "prop-types";
import _react from "react";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (e, t) {
  exports = t(_propTypes, _react);
}(window, function (r, n) {
  return a = {}, o.m = i = [function (e, t) {
    e.exports = r;
  }, function (e, t) {
    e.exports = n;
  }, function (e, t, r) {
    "use strict";

    r.r(t), r.d(t, "namedSizes", function () {
      return s;
    }), r.d(t, "Checkmark", function () {
      return c;
    });

    var n = r(1),
        i = r.n(n),
        o = r(0),
        a = r.n(o),
        s = (r(3), {
      small: 16,
      medium: 24,
      large: 52,
      xLarge: 72,
      xxLarge: 96
    }),
        c = function (e) {
      var t = e.size,
          r = e.color,
          n = s[t] || t,
          o = {
        width: n,
        height: n
      };
      return r && (o["--checkmark-fill-color"] = r), i.a.createElement("svg", {
        className: "checkmark",
        xmlns: "http://www.w3.org/2000/svg",
        style: o,
        viewBox: "0 0 52 52"
      }, i.a.createElement("circle", {
        className: "checkmark__circle",
        cx: "26",
        cy: "26",
        r: "25",
        fill: "none"
      }), i.a.createElement("path", {
        className: "checkmark__check",
        fill: "none",
        d: "M14.1 27.2l7.1 7.2 16.7-16.8"
      }));
    };

    c.propTypes = {
      size: a.a.oneOfType([a.a.string, a.a.number]),
      color: a.a.string
    }, c.defaultProps = {
      size: "large"
    };
  }, function (e, t, r) {
    var n = r(4);
    "string" == typeof n && (n = [[e.i, n, ""]]);
    var o = {
      hmr: !0,
      transform: void 0,
      insertInto: void 0
    };
    r(6)(n, o);
    n.locals && (e.exports = n.locals);
  }, function (e, t, r) {
    (e.exports = r(5)(!1)).push([e.i, ":root{--checkmark-fill-color:#7ac142;--checkmark-arrow-color:#fff;--checkmark-arrow-thickness:5}.checkmark{display:block;margin-left:auto;margin-right:auto;border-radius:50%;stroke:var(--checkmark-arrow-color);stroke-width:var(--checkmark-arrow-thickness);stroke-miterlimit:10;//box-shadow:inset 0 0 0 var(--checkmark-fill-color);animation:fill .4s ease-in-out .4s forwards,scale .3s ease-in-out .9s both}.checkmark__circle{stroke-dasharray:166;stroke-dashoffset:166;stroke-width:var(--checkmark-arrow-thickness);stroke-miterlimit:10;stroke:var(--checkmark-fill-color);fill:none;animation:stroke .6s cubic-bezier(.65,0,.45,1) forwards}.checkmark__check{transform-origin:50% 50%;stroke-dasharray:48;stroke-dashoffset:48;animation:stroke .3s cubic-bezier(.65,0,.45,1) .8s forwards}@keyframes stroke{to{stroke-dashoffset:0}}@keyframes scale{0%,to{transform:none}50%{transform:scale3d(1.1,1.1,1)}}@keyframes fill{to{box-shadow:inset 0 0 0 100vh var(--checkmark-fill-color)}}", ""]);
  }, function (e, t) {
    e.exports = function (r) {
      var a = [];
      return a.toString = function () {
        return this.map(function (e) {
          var t = function (e, t) {
            var r = e[1] || "",
                n = e[3];
            if (!n) return r;

            if (t && "function" == typeof btoa) {
              var o = function (e) {
                return "/*# sourceMappingURL=data:application/json;charset=utf-8;base64," + btoa(unescape(encodeURIComponent(JSON.stringify(e)))) + " */";
              }(n),
                  i = n.sources.map(function (e) {
                return "/*# sourceURL=" + n.sourceRoot + e + " */";
              });

              return [r].concat(i).concat([o]).join("\n");
            }

            return [r].join("\n");
          }(e, r);

          return e[2] ? "@media " + e[2] + "{" + t + "}" : t;
        }).join("");
      }, a.i = function (e, t) {
        "string" == typeof e && (e = [[null, e, ""]]);

        for (var r = {}, n = 0; n < (this || _global).length; n++) {
          var o = (this || _global)[n][0];
          "number" == typeof o && (r[o] = !0);
        }

        for (n = 0; n < e.length; n++) {
          var i = e[n];
          "number" == typeof i[0] && r[i[0]] || (t && !i[2] ? i[2] = t : t && (i[2] = "(" + i[2] + ") and (" + t + ")"), a.push(i));
        }
      }, a;
    };
  }, function (e, t, n) {
    var r,
        o,
        i,
        c = {},
        f = (r = function () {
      return window && document && document.all && !window.atob;
    }, function () {
      return void 0 === o && (o = r.apply(this || _global, arguments)), o;
    }),
        a = (i = {}, function (e, t) {
      if ("function" == typeof e) return e();

      if (void 0 === i[e]) {
        var r = function (e, t) {
          return t ? t.querySelector(e) : document.querySelector(e);
        }.call(this || _global, e, t);

        if (window.HTMLIFrameElement && r instanceof window.HTMLIFrameElement) try {
          r = r.contentDocument.head;
        } catch (e) {
          r = null;
        }
        i[e] = r;
      }

      return i[e];
    }),
        s = null,
        u = 0,
        l = [],
        p = n(7);

    function d(e, t) {
      for (var r = 0; r < e.length; r++) {
        var n = e[r],
            o = c[n.id];

        if (o) {
          o.refs++;

          for (var i = 0; i < o.parts.length; i++) o.parts[i](n.parts[i]);

          for (; i < n.parts.length; i++) o.parts.push(k(n.parts[i], t));
        } else {
          var a = [];

          for (i = 0; i < n.parts.length; i++) a.push(k(n.parts[i], t));

          c[n.id] = {
            id: n.id,
            refs: 1,
            parts: a
          };
        }
      }
    }

    function h(e, t) {
      for (var r = [], n = {}, o = 0; o < e.length; o++) {
        var i = e[o],
            a = t.base ? i[0] + t.base : i[0],
            s = {
          css: i[1],
          media: i[2],
          sourceMap: i[3]
        };
        n[a] ? n[a].parts.push(s) : r.push(n[a] = {
          id: a,
          parts: [s]
        });
      }

      return r;
    }

    function m(e, t) {
      var r = a(e.insertInto);
      if (!r) throw new Error("Couldn't find a style target. This probably means that the value for the 'insertInto' parameter is invalid.");
      var n = l[l.length - 1];
      if ("top" === e.insertAt) n ? n.nextSibling ? r.insertBefore(t, n.nextSibling) : r.appendChild(t) : r.insertBefore(t, r.firstChild), l.push(t);else if ("bottom" === e.insertAt) r.appendChild(t);else {
        if ("object" != typeof e.insertAt || !e.insertAt.before) throw new Error("[Style Loader]\n\n Invalid value for parameter 'insertAt' ('options.insertAt') found.\n Must be 'top', 'bottom', or Object.\n (https://github.com/webpack-contrib/style-loader#insertat)\n");
        var o = a(e.insertAt.before, r);
        r.insertBefore(t, o);
      }
    }

    function v(e) {
      if (null === e.parentNode) return !1;
      e.parentNode.removeChild(e);
      var t = l.indexOf(e);
      0 <= t && l.splice(t, 1);
    }

    function b(e) {
      var t = document.createElement("style");

      if (void 0 === e.attrs.type && (e.attrs.type = "text/css"), void 0 === e.attrs.nonce) {
        var r = function () {
          0;
          return n.nc;
        }();

        r && (e.attrs.nonce = r);
      }

      return y(t, e.attrs), m(e, t), t;
    }

    function y(t, r) {
      Object.keys(r).forEach(function (e) {
        t.setAttribute(e, r[e]);
      });
    }

    function k(t, e) {
      var r, n, o, i;

      if (e.transform && t.css) {
        if (!(i = "function" == typeof e.transform ? e.transform(t.css) : e.transform.default(t.css))) return function () {};
        t.css = i;
      }

      if (e.singleton) {
        var a = u++;
        r = s = s || b(e), n = x.bind(null, r, a, !1), o = x.bind(null, r, a, !0);
      } else o = t.sourceMap && "function" == typeof URL && "function" == typeof URL.createObjectURL && "function" == typeof URL.revokeObjectURL && "function" == typeof Blob && "function" == typeof btoa ? (r = function (e) {
        var t = document.createElement("link");
        return void 0 === e.attrs.type && (e.attrs.type = "text/css"), e.attrs.rel = "stylesheet", y(t, e.attrs), m(e, t), t;
      }(e), n = function (e, t, r) {
        var n = r.css,
            o = r.sourceMap,
            i = void 0 === t.convertToAbsoluteUrls && o;
        (t.convertToAbsoluteUrls || i) && (n = p(n));
        o && (n += "\n/*# sourceMappingURL=data:application/json;base64," + btoa(unescape(encodeURIComponent(JSON.stringify(o)))) + " */");
        var a = new Blob([n], {
          type: "text/css"
        }),
            s = e.href;
        e.href = URL.createObjectURL(a), s && URL.revokeObjectURL(s);
      }.bind(null, r, e), function () {
        v(r), r.href && URL.revokeObjectURL(r.href);
      }) : (r = b(e), n = function (e, t) {
        var r = t.css,
            n = t.media;
        n && e.setAttribute("media", n);
        if (e.styleSheet) e.styleSheet.cssText = r;else {
          for (; e.firstChild;) e.removeChild(e.firstChild);

          e.appendChild(document.createTextNode(r));
        }
      }.bind(null, r), function () {
        v(r);
      });

      return n(t), function (e) {
        if (e) {
          if (e.css === t.css && e.media === t.media && e.sourceMap === t.sourceMap) return;
          n(t = e);
        } else o();
      };
    }

    e.exports = function (e, a) {
      if ("undefined" != typeof DEBUG && DEBUG && "object" != typeof document) throw new Error("The style-loader cannot be used in a non-browser environment");
      (a = a || {}).attrs = "object" == typeof a.attrs ? a.attrs : {}, a.singleton || "boolean" == typeof a.singleton || (a.singleton = f()), a.insertInto || (a.insertInto = "head"), a.insertAt || (a.insertAt = "bottom");
      var s = h(e, a);
      return d(s, a), function (e) {
        for (var t = [], r = 0; r < s.length; r++) {
          var n = s[r];
          (o = c[n.id]).refs--, t.push(o);
        }

        e && d(h(e, a), a);

        for (r = 0; r < t.length; r++) {
          var o;

          if (0 === (o = t[r]).refs) {
            for (var i = 0; i < o.parts.length; i++) o.parts[i]();

            delete c[o.id];
          }
        }
      };
    };

    var g,
        w = (g = [], function (e, t) {
      return g[e] = t, g.filter(Boolean).join("\n");
    });

    function x(e, t, r, n) {
      var o = r ? "" : n.css;
      if (e.styleSheet) e.styleSheet.cssText = w(t, o);else {
        var i = document.createTextNode(o),
            a = e.childNodes;
        a[t] && e.removeChild(a[t]), a.length ? e.insertBefore(i, a[t]) : e.appendChild(i);
      }
    }
  }, function (e, t) {
    e.exports = function (e) {
      var t = "undefined" != typeof window && window.location;
      if (!t) throw new Error("fixUrls requires window.location");
      if (!e || "string" != typeof e) return e;
      var o = t.protocol + "//" + t.host,
          i = o + t.pathname.replace(/\/[^\/]*$/, "/");
      return e.replace(/url\s*\(((?:[^)(]|\((?:[^)(]+|\([^)(]*\))*\))*)\)/gi, function (e, t) {
        var r,
            n = t.trim().replace(/^"(.*)"$/, function (e, t) {
          return t;
        }).replace(/^'(.*)'$/, function (e, t) {
          return t;
        });
        return /^(#|data:|http:\/\/|https:\/\/|file:\/\/\/|\s*$)/i.test(n) ? e : (r = 0 === n.indexOf("//") ? n : 0 === n.indexOf("/") ? o + n : i + n.replace(/^\.\//, ""), "url(" + JSON.stringify(r) + ")");
      });
    };
  }], o.c = a, o.d = function (e, t, r) {
    o.o(e, t) || Object.defineProperty(e, t, {
      enumerable: !0,
      get: r
    });
  }, o.r = function (e) {
    "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
      value: "Module"
    }), Object.defineProperty(e, "__esModule", {
      value: !0
    });
  }, o.t = function (t, e) {
    if (1 & e && (t = o(t)), 8 & e) return t;
    if (4 & e && "object" == typeof t && t && t.__esModule) return t;
    var r = Object.create(null);
    if (o.r(r), Object.defineProperty(r, "default", {
      enumerable: !0,
      value: t
    }), 2 & e && "string" != typeof t) for (var n in t) o.d(r, n, function (e) {
      return t[e];
    }.bind(null, n));
    return r;
  }, o.n = function (e) {
    var t = e && e.__esModule ? function () {
      return e.default;
    } : function () {
      return e;
    };
    return o.d(t, "a", t), t;
  }, o.o = function (e, t) {
    return Object.prototype.hasOwnProperty.call(e, t);
  }, o.p = "", o(o.s = 2);

  function o(e) {
    if (a[e]) return a[e].exports;
    var t = a[e] = {
      i: e,
      l: !1,
      exports: {}
    };
    return i[e].call(t.exports, t, t.exports, o), t.l = !0, t.exports;
  }

  var i, a;
});
export default exports;
export const reactCheckmark = exports.reactCheckmark;